<template>
	<div>
		<TitleMore :title="['四川省红十字会领导']" :isMore="false"></TitleMore>
		<div class="baseCard bossIntro">
			<!-- <div class="bossTitle">
        <p class="introTitle">{{item.position}}</p>
        <span style="font-size: 16px;">{{item.title}}</span>
      </div> -->
			<div
				style="width:100%;margin-bottom:20px"
				v-for="item in hszhld"
				:key="item.id"
			>
				<div class="bossTitle">
					<p class="introTitle">{{ item.position }}</p>
					<span class="bossName">{{ item.title }}</span>
				</div>
				<div class="bossInfo">
					<img
						v-if="item.smallImage"
						style="width:180px;height:250px;margin-right:20px"
						:src="item.smallImage"
						alt=""
					/>
					<div v-if="item.smallImage" style="flex: 1;">
						<!-- <p style="
font-size: 18px;
font-weight: bold;
margin:20px">{{item.title}}</p> -->
						<p
							style="
font-size: 16px;
line-height:20px"
						>
							{{ item.metaDescription }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<TitleMore
			:title="['四川省红十字会第八届理事会']"
			style="margin-top:40px"
			:isMore="false"
		></TitleMore>
		<div class="baseCard bossIntro">
			<div class="bossTitle" v-for="item in lsh" :key="item.id">
				<p class="introTitle">{{ item.position }}</p>
				<div>
					<span class="bossName" style="margin-right:10px">{{
						item.title
					}}</span>
					<!-- <span style="font-size: 16px;margin-right:10px">袁波</span>
          <span style="font-size: 16px;margin-right:10px">伍旭</span> -->
				</div>
			</div>
		</div>
		<TitleMore
			:title="['四川省红十字会执行委员会']"
			style="margin-top:40px"
			:isMore="false"
		></TitleMore>
		<div class="baseCard bossIntro">
			<div class="bossTitle" v-for="item in wyh" :key="item.id">
				<p class="introTitle">{{ item.position }}</p>
				<div>
					<span class="bossName" style="margin-right:10px">{{
						item.title
					}}</span>
					<!-- <span style="font-size: 16px;margin-right:10px">袁波</span>
	      <span style="font-size: 16px;margin-right:10px">伍旭</span> -->
				</div>
			</div>
		</div>
		<TitleMore
			:title="['四川省红十字会第八届监事会']"
			style="margin-top:40px"
			:isMore="false"
		></TitleMore>
		<div class="baseCard bossIntro">
			<div class="bossTitle" v-for="item in jsh" :key="item.id">
				<p class="introTitle">{{ item.position }}</p>
				<div>
					<span class="bossName" style="margin-right:10px">{{
						item.title
					}}</span>
					<!-- <span style="font-size: 16px;margin-right:10px">袁波</span>
          <span style="font-size: 16px;margin-right:10px">伍旭</span> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import apiUrls from "@api";
export default {
	data() {
		return {
			lsh: [],
			jsh: [],
			wyh: [],
			hszhld: [],
		};
	},

	components: {
		TitleMore,
	},

	mounted() {
		this.getList("lsh");
		this.getList("jsh");
		this.getList("zxwyh");
		this.getList("hszhld");
	},

	methods: {
		getList(node) {
			apiUrls.selfDynamicList({ node, size: 100 }).then((res) => {
				if (node == "lsh") {
					this.lsh = res.results.data;
					// let nameArr=[]
					// res.results.data.map(el=>{
					//   nameArr.push(el.title)
					//   el.nameStr=nameArr
					// })
					// this.lsh = {};
				} else if (node == "jsh") {
					this.jsh = res.results.data;
				} else if (node == "zxwyh") {
					this.wyh = res.results.data;
				} else {
					this.hszhld = res.results.data;
				}
			});
		},
	},
};
</script>
<style lang="less" scoped>
.bossIntro {
	width: 1080px;
	padding: 60px;
	.bossTitle {
		width: 100%;
		border-top: 1px solid #e5e5e5;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 0;
		.introTitle {
			display: inline-block;
			font-size: 18px;
			font-weight: bold;
			color: #d1292e;
			line-height: 48px;
			padding-right: 15px;
		}
		.bossName {
			font-size: 16px;
			display: inline-block;
			width: 500px;
			line-height: 25px;
		}
	}
	.bossInfo {
		width: 60%;
		display: flex;
		justify-content: space-around;
		margin: 0 auto;
	}
}
</style>
